import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as $ from "jquery";
import { Page } from 'src/app/Models/PageModel';
import { Design } from 'src/app/Models/designModel';
import { StaticPageService } from 'src/app/services/static-page.service';
import Swal from 'sweetalert2';

declare const unlayer;

interface PageParamers {
    token: string,
    pageId: string
}

@Component({
    selector: 'app-static-page',
    templateUrl: './static-page.component.html',
    styleUrls: ['./static-page.component.scss'],
    providers: [StaticPageService]
})
export class StaticPageComponent  {

    public editor;
    public page: Page;

    constructor(
        private readonly staticPageservice: StaticPageService,
        private readonly route: ActivatedRoute
    ) {
        this.startMonitoringParams();
    }

    private loadEditor(design: Design): void {
        let editorTimer = setInterval(() => {
            let editor = document.getElementById('editor-container');
            if (editor) {
                let wH = $(window).outerHeight();
                let cT = $(editor).offset().top;
                $(editor).height(wH - cT - 70);
                clearInterval(editorTimer);

                this.editor = unlayer.createEditor({
                    id: 'editor-container',
                    projectId: 1234,
                    displayMode: 'web',
                    appearance: {
                        theme: 'light',
                        panels: {
                            tools: {
                                dock: 'right'
                            }
                        }
                    }
                })

                this.editor.loadDesign(design);
            }
        }, 500);
    }

    private getDataPageById(): void {
        let pageId: string = sessionStorage.getItem('pageId');
        this.staticPageservice.getResource(pageId).subscribe({
            next: (response: any) => {
                this.page = response.data;
                this.loadEditor(response.data.design);
            }
        });
    }

    private startMonitoringParams(): void {
        this.route.params.subscribe({
          next: (params: PageParamers) => {
            sessionStorage.setItem('token', 'Bearer ' + params.token);
            sessionStorage.setItem('pageId', params.pageId);
        }
    });
    this.route.queryParams.subscribe({
        next: (params: PageParamers) => {
            sessionStorage.setItem('token', 'Bearer ' + params.token);
            console.log('token', params.token);
        }
    });
    this.getDataPageById();
}

    private normalizeHTML(html): string {
        return html.split('body,')
            .join('body.never,')
            .split('body {')
            .join('body.never {');
    }

    public savePage() {
        this.editor.exportHtml((data) => {
            const html = this.normalizeHTML(data.html);
            this.page.text = html;
            this.page.design = data.design;
            this.staticPageservice.createResource(this.page).subscribe({
                next: () => {
                    Swal.fire({
                        title: "Salvo",
                        text: `Página '${this.page.title}' Salva com sucesso!`,
                        icon: "success"
                    });
                }
            });
        })
    }
}
